import React, { useState } from 'react'
import './ProjectPage.css';

/* Modal */
import Modal from "./Modal";

/* React router */
import { NavLink } from 'react-router-dom';

/* Componet */
import HeaderPage from '../../components/Header/HeaderPage';
import Footer from '../../components/Footer/Footer';
// import ParticleBackground from "../../components/ParticlesBg/ParticleBackground";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Img */
const proyectsImg = require.context('../../img', true);

const Project = () => {
  const [estadoModal17, cambiarEstadoModal17] = useState(false);
  const [estadoModal14, cambiarEstadoModal14] = useState(false);
  const [estadoModal15, cambiarEstadoModal15] = useState(false);
  const [estadoModal16, cambiarEstadoModal16] = useState(false);
  

  return (
    <div>

      <HeaderPage />

      {/* <ParticleBackground /> */}

      <main>
        <section className="proyectos mas-proyect" id="proyectos">
          <h1 className="heading" data-section="Nav" data-value="projects">
            <FormattedMessage
              id='projects'
              defaultMessage='Projects'
            />
          </h1>
          <nav className="navbar nav-proj">
            <NavLink to="/project" offset={-150} duration={500}>
              <FormattedMessage
                id='site-web'
                defaultMessage='Publications and Working Papers'
              />
            </NavLink>
            {/* <NavLink to="/project/app" offset={-150} duration={500}>
              Apps
            </NavLink> */}
            <NavLink to="/project/Event" offset={-150} duration={500}>
              <FormattedMessage
                id='events'
                defaultMessage='Events and Workshops'
              />
            </NavLink>
          </nav>
        </section>

        <section className="projects__grid paginas-web">
          <div className="projects__item">
            <a onClick={() => cambiarEstadoModal17(!estadoModal17)}>
              <img src={proyectsImg(`./proyecto-1.gif`)} alt="" className="projects__img" />
              <div className = "overlay_text">Hey Google! What's in the news?</div>
            </a>
          </div>
          <div className="projects__item">
            <a onClick={() => cambiarEstadoModal14(!estadoModal14)}>
              <img src={proyectsImg(`./news-recommenders-explained.jpg`)} alt="" className="projects__img" />
              <div className = "overlay_text">News recommenders explained </div>
                       
            </a>
          </div>
          <div className="projects__item">
            <a onClick={() => cambiarEstadoModal15(!estadoModal15)}>
              <img src={proyectsImg(`./proyecto-4.png`)} alt="" className="projects__img" />
              <div className = "overlay_text">Collaborative Coding Cultures</div>
            </a>
          </div>
          <div className="projects__item">
            <a onClick={() => cambiarEstadoModal16(!estadoModal16)}>
              <img src={proyectsImg(`./collaborative-coding-cultures.jpg`)} className="projects__img" />
              <div className = "overlay_text">Popularity-driven Metrics</div>
            </a>
          </div>
          
        </section>
      </main>

      <Modal
        estado={estadoModal17}
        cambiarEstado={cambiarEstadoModal17}
      >
        <div className="content-modal">
          <div className="pw-content">
            <div className="eins-modal-preview"><img src={proyectsImg(`./proyecto-1.gif`)} alt="" /></div>
            <div className="eins-modal-text">
              <p>
                <b>
                <FormattedMessage
                  id='projects-info-17-p1'
                  defaultMessage='Hey Google! What is in the news? The effects of a conversational agent in issue salience'
                />
                </b>
              </p>
              <p>
                <FormattedMessage
                  id='projects-info-17-p2'
                  defaultMessage='The article explores how conversational agents, such as Google Assistant, influence the transfer of issue salience from the channel to the user and compares the results with other channels for news consumption. The study conducted a seven-day longitudinal survey of users consuming news through various channels and found that those consuming news via CAs may have a lower probability of agreeing on what issues are more important to the country compared to participants consuming information on social media and news websites. The article raises questions about the impact and democratic role of CAs in the overall news ecology.'
                />
              </p>
              <div className="eins-modal-text-2">
                <span>Details:</span> <span> Resendez, V., Araujo, T., Helberger N., de Vreese, C. (2023). Hey Google! What is in the news? Digital Journalism [Under review]. Amsterdam School of Communication Research. University of Amsterdam </span>
              </div>
              
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        estado={estadoModal14}
        cambiarEstado={cambiarEstadoModal14}
      >
        <div className="content-modal">
          <div className="pw-content">
            <div className="eins-modal-preview"><img src={proyectsImg(`./news-recommenders-explained.jpg`)} alt="" /></div>
            <div className="eins-modal-text">
              <p>
                <b>
                <FormattedMessage
                  id='projects-info-14-p1'
                  defaultMessage='News recommenders explained. The effects of explainability and modality on trust, privacy concerns and perceived personalization'
                />
                </b>
              </p>
              <p>
                <FormattedMessage
                  id='projects-info-14-p2'
                  defaultMessage='The increasing use of algorithmically driven news recommendations has raised concerns about their lack of transparency and explainability. This has led researchers to emphasize the need for clearer explanations of these systems, with a focus on the level of detail that should be included (Büchi et al., 2021). Additionally, the manner in which information is delivered, such as audio or visual channels, can impact the perceptions of users, including their views on privacy and personalization (Turró et al., 2019). This article aims to investigate the effects of explainability on trust and the relationship between explainability, modality, privacy concerns and perceived personalization. To do so, a 2x2 survey experiment will be conducted to explore the influence of explainability (global vs local) and modality (audio + text vs text only) on users. The results of this study aim to provide valuable insights into the design of explanations in different modalities, and assess the impact of explainability on multiple types of news recommenders. '
                />
              </p>
              <div className="eins-modal-text-2">
                <span>Details:</span> <span> Resendez, V., Araujo, T., Helberger N., de Vreese, C. (2023). News recommenders explained. The effects of explainability and modality on trust, privacy concerns and perceived personalization [Work in progress]. Amsterdam School of Communication Research. University of Amsterdam </span>
              </div>
              
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        estado={estadoModal15}
        cambiarEstado={cambiarEstadoModal15}
      >
        <div className="content-modal">
          <div className="pw-content">
            <div className="eins-modal-preview"><img src={proyectsImg(`./proyecto-4.png`)} alt="" /></div>
            <div className="eins-modal-text">
              <p>
                <b>
                <FormattedMessage
                  id='projects-info-15-p1'
                  defaultMessage='Collaborative Coding Cultures: How Journalists Use GitHub as a Trading Zone'
                />
                </b>
              </p>
              <p>
                <FormattedMessage
                  id='projects-info-15-p2'
                  defaultMessage='As the use of ‘big data’ in journalism has become an increasing practice in recent years, its exercise is no longer limited to newsrooms in Europe and North America. The prevalence of coding cultures and collaboration in newsrooms worldwide has risen, in part to tackle challenges related to information overflow. This collaboration has been facilitated partly by the popularization of low-cost code hosting platforms such as GitHub. GitHub is a well-known public code management system with a significant potential for transparency and collaboration between communities. This study aims to map how newsrooms employ GitHub as a trading zone for collaborative journalism. We analyze the GitHub activities of leading media outlets from 26 countries. Based on content analysis of the newsroom’s repositories and users’ profiles, we found that media organizations in the United Kingdom and the United States continue to be the primary code producers in GitHub. However, our results show that newsrooms in the Global South benefit from this collaboration by contributing and stargazing these projects. The results of this article have important implications for understanding how newsrooms across the globe use GitHub for collaboration and the role journalistic code plays in bringing together various collaborative initiatives in journalism.'
                />
              </p>
              <div className="eins-modal-text-2">
                <span>Details:</span> <span> Dodds, T., Resendez, V., von Nordheim, G., Moller., J., Araujo, T., (2023). Collaborative coding cultures: How journalists use GitHub as a trading zone. Digital Journalism [Under review]. Amsterdam School of Communication Research. University of Amsterdam </span>
              </div>
              
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        estado={estadoModal16}
        cambiarEstado={cambiarEstadoModal16}
      >
        <div className="content-modal">
          <div className="pw-content">
            <div className="eins-modal-preview"><img src={proyectsImg(`./collaborative-coding-cultures.jpg`)} alt="" /></div>
            <div className="eins-modal-text">
              <p>
                <b>
                <FormattedMessage
                  id='projects-info-16-p1'
                  defaultMessage='Popularity-driven Metrics: Audience Analytics and Shifting Opinion Power to Digital Platforms'
                />
                </b>
              </p>
              <p>
                <FormattedMessage
                  id='projects-info-16-p2'
                  defaultMessage='As digital technologies have made their way into news production, allowing news organizations to measure audience behaviors and engagement in real-time, click-based and editorial goals have become increasingly intertwined. Ongoing developments in algorithmic technologies allow editors to bring their audience into the newsroom using specialized tools such as Chartbeat or Google Analytics. This article examines how these technologies have affected the composition of the audience and their power to influence news-making processes inside two Chilean newsrooms. Drawing on several months of newsroom ethnography, we identify how the pursuit of “clickable news” impacts editorial processes and journalistic priorities by changing the datafied audience opinion power behind news production. Shifts in opinion power, loss of control, and increased platform dependency may contribute to a concentrated media landscape. Our findings show that opinion power has shifted to a datafied version of the audience, raising new questions about platform dependency and editorial autonomy in media organizations. These results carry significant implications for understanding the chase for traffic in current multiplatform newsrooms and how this phenomenon impacts news production.'
                />
              </p>
              <div className="eins-modal-text-2">
                <span>Details:</span> <span> Dodds, T., de Vreese, C., Helberger, N., Resendez, V., & Seipp, T. (2023). Popularity-driven Metrics: Audience Analytics and Shifting Opinion Power to Digital Platforms. Journalism Studies, 1-19.</span> 
              </div>
              
            </div>
          </div>
        </div>
      </Modal>
      <ScrollToTop />

      <Footer />
    </div>
  )
}
export default Project;