import React, { useState } from 'react'
import './ProjectPage.css';

/* Modal */
import Modal from "./Modal";

/* React router */
import { NavLink } from 'react-router-dom';

/* Componet */
import HeaderPage from '../../components/Header/HeaderPage';
import Footer from '../../components/Footer/Footer';
// import ParticleBackground from "../../components/ParticlesBg/ParticleBackground";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Img */
const proyectsImg = require.context('../../img', true);

const ProjectEvent = () => {
    const [estadoModal4, cambiarEstadoModal4] = useState(false);
    const [estadoModal3, cambiarEstadoModal3] = useState(false);
    const [estadoModal2, cambiarEstadoModal2] = useState(false);
    const [estadoModal1, cambiarEstadoModal1] = useState(false);


    return (
        <div>

            <HeaderPage />

            {/* <ParticleBackground /> */}

            <main>
                <section className="proyectos mas-proyect" id="proyectos">
                    <h1 className="heading">
                        <FormattedMessage
                            id='projects'
                            defaultMessage='Projects'
                        />
                    </h1>
                    <nav className="navbar nav-proj">
                        <NavLink to="/project/" offset={-150} duration={500}>
                            <FormattedMessage
                                id='site-web'
                                defaultMessage='Publications and Working Papers'
                            />
                        </NavLink>
                        
                        <NavLink to="/project/event" offset={-150} duration={500}>
                            <FormattedMessage
                                id='events'
                                defaultMessage='Workshops and Events'
                            />
                        </NavLink>
                    </nav>
                </section>

                <section className="projects__grid events">
                   
                    <div className="projects__item">
                        <a onClick={() => cambiarEstadoModal2(!estadoModal2)}>
                            <img src={proyectsImg(`./ICA.png`)} alt="" className="projects__img" />
                            <div className = "overlay_text">Building the Conditions for Responsible Human-Centric AI Systems</div>
                        </a>
                    </div>
                    <div className="projects__item">
                        <a onClick={() => cambiarEstadoModal1(!estadoModal1)}>
                            <img src={proyectsImg(`./cecor-logo.png`)} alt="" className="projects__img" />
                            <div className = "overlay_text">Cutting-edge Communication Research</div>
                        </a>
                    </div>
                    
                    <div className="projects__item">
                        {/* <Events and workshops /> */}
                        <a onClick={() => cambiarEstadoModal3(!estadoModal3)}>
                            <img src={proyectsImg(`./AIinNewsrooms.png`)} alt="" className="projects__img" />
                            <div className = "overlay_text">AI for the public: Engaging with new audiences</div>
                        </a>
                    </div>
                    <div className="projects__item">
                        {/* <Publications and working papers /> */}
                        <a onClick={() => cambiarEstadoModal4(!estadoModal4)}>
                            <img src={proyectsImg(`./FutureOfNews.png`)} alt="" className="projects__img" />
                            <div className = "overlay_text">The future of news with voice assistants</div>
                        </a>
                    </div>
                </section>
            </main>

            <Modal
                estado={estadoModal4}
                cambiarEstado={cambiarEstadoModal4}
            >
                <div className="content-modal">
                    <div className="pw-content">
                        <div className="eins-modal-preview"><img src={proyectsImg(`./FutureOfNews.png`)} alt="" /></div>
                        <div className="eins-modal-text">
                            <p>
                                <b>
                                The future of news with voice assistants
                                </b>
                            </p>
                            <p>
                                <FormattedMessage
                                    id='projectsevent-info-4-p2'
                                    defaultMessage="Voice assistants are not new but are becoming more popular. While voice technologies are still under development, their potential as voice concierges will create new ways for newsrooms to engage with their audiences. This technological revolution enhanced by smart assistants brings multiple concerns for the media ecosystem. In this discussion, we will address questions such as: What is the potential of these technologies? Who are the lead developers? How are the monetization strategies for newsrooms planned with voice AI technologies?"
                                />
                            </p>
                            <div className="eins-modal-text-2">
                                <span>Details:</span> <a href='https://www.youtube.com/watch?v=l6BVAqrwT5A&t=315s' target="_blank">hhttps://www.youtube.com/watch?v=l6BVAqrwT5A&t=315s</a>
                            </div>
                    
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                estado={estadoModal3}
                cambiarEstado={cambiarEstadoModal3}
            >
                <div className="content-modal">
                    <div className="pw-content">
                        <div className="eins-modal-preview"><img src={proyectsImg(`./AIinNewsrooms.png`)} alt="" /></div>
                        <div className="eins-modal-text">
                            <p>
                                <b>
                                <FormattedMessage
                                    id='projectsevent-info-3-p1'
                                    defaultMessage='AI for the public: Engaging with new audiences'
                                />
                                </b>
                            </p>
                            <p>
                                <FormattedMessage
                                    id='projectsevent-info-3-p2'
                                    defaultMessage="During this event, we will explore all the new possibilities, products, and ideas that AI technologies are making possible inside newsrooms across the world. Drawing from our panellists' research and experience, we are set to find the ‘holy grail’ of interacting with new audiences. With this discussion, we want to share with journalists and other media workers how AI innovations can be used better to understand their audiences and grow engagement. The key question guiding the conversation is: What are new AI technologies in the newsroom that are changing the interaction with the audience? However, in this context, the adoption of AI for news distribution also points out ethical challenges (e.g., transparency and diversity)  that affect the interaction with the audience. Thus, a great point of interest in this conversation is also to brainstorm distinct ways of archiving significant engagement while securing ethical values in the design of algorithmically mediated channels."
                                />
                            </p>
                            <div className="eins-modal-text-2">
                                <span>Details:</span> <a href="https://youtu.be/y5v2-p8fnKw" target="_blank">https://youtu.be/y5v2-p8fnKw</a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                estado={estadoModal2}
                cambiarEstado={cambiarEstadoModal2}
            >
                <div className="content-modal">
                    <div className="pw-content">
                        <div className="eins-modal-preview"><img src={proyectsImg(`./ICA.png`)} alt="" /></div>
                        <div className="eins-modal-text">
                            <p>
                                <b>
                                <FormattedMessage
                                    id='projectsevent-info-2-p1'
                                    defaultMessage='Building the Conditions for Responsible Human-Centric AI Systems'
                                />
                                </b>
                            </p>
                            <p>
                                <FormattedMessage
                                    id='projectsevent-info-2-p2'
                                    defaultMessage=" As AI-based technologies continuously transform the media ecosystem, there is an increasing need for a regulatory framework promoting human-centric AI with shared values. Accordingly, the need to discuss new regulations promoting public values such as transparency, authenticity, and diversity is critical to empower communities worldwide. This pre-conference aims to bridge the gap between regulatory proposals and communication research through direct exchanges with policy leaders and by operationalising and discussing concrete suggestions to develop human-centric systems."
                                />
                            </p>
                            <div className="eins-modal-text-2">
                                <span>Details:</span> <a href="https://www.icahdq.org/mpage/ICA23-BuildingConditions" target="_blank">https://www.icahdq.org/mpage/ICA23-BuildingConditions</a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                estado={estadoModal1}
                cambiarEstado={cambiarEstadoModal1}
            >
                <div className="content-modal">
                    <div className="pw-content">
                        <div className="eins-modal-preview"><img src={proyectsImg(`./cecor-logo.png`)} alt="" /></div>
                        <div className="eins-modal-text">
                            <p>
                                <b>
                                <FormattedMessage
                                    id='projectsevent-info-1-p1'
                                    defaultMessage='Cutting-edge Communication Research'
                                />
                                </b>
                            </p>
                            <p>
                                <FormattedMessage
                                    id='projectsevent-info-1-p2'
                                    defaultMessage="Cutting-edge Communication Research @ ASCoR (CeCoR @ ASCoR) is a series of online research talks that aims to offer external early career researchers a welcoming and stimulating forum for presenting and discussing research innovations in Communication Science."
                                />
                                <FormattedMessage
                                    id='projectsevent-info-1-p2'
                                    defaultMessage="I organized some of the sessions with colleages presenting their research:"
                                />
                                
                                <ul>
                                    <li><FormattedMessage
                                    id='projectsevent-info-1-p2'
                                    defaultMessage="Felix Simon (Oxford University) - 'How AI Reshapes News Organisations' Dependency on Platform Companies'"
                                    />
                                    </li>
                                    <li>Lisa Weidmüller and Katrin Etzrodt (Technical University of Dresden) - "Voice-based assistants as media intermediaries.</li>
                                    <li>Ernesto de León (University of Bern) - "Hyper-partisan, alternative and conspiracy media."</li>
                                </ul>

                            </p>
                            <div className="eins-modal-text-2">
                                <span>Details:</span> <a href="https://ascor.uva.nl/events/cecor/cutting-edge-communication-research-at-ascor.html" target="_blank">https://ascor.uva.nl/events/cecor/cutting-edge-communication-research-at-ascor.html</a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Modal>

            <ScrollToTop />

            <Footer />
        </div>
    )
}
export default ProjectEvent;
