import React from 'react';
import './Content.css';

/* ReactScroll */
import { Link } from 'react-scroll';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

const Content = () => (
    <div className="contenido">
        {/* <ParticleHeaderBg/> */}
        <section className="inicio" id="inicio">
        
            <div className="titulo">
               <div>
                </div> 
                
                <h1 data-aos="fade-up" data-aos-delay="800">
                    <FormattedMessage
                        id='name'
                        defaultMessage='Valeria Resendez'
                    />
                </h1>
                <p data-aos="fade-up" data-aos-delay="1000">
                    <FormattedMessage
                        id='rol'
                        defaultMessage='Researcher'
                    />
                </p>
                
                <div className="redes-sociales">
                    <a href="https://www.linkedin.com/in/valeriaresendez/" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1200"><i className="fab fa-linkedin"></i></a>
                    <a href="https://orcid.org/0000-0003-3623-5728" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1800"><i className="fab fa-orcid"></i></a>
                    <a href="https://twitter.com/vresendezg" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1400"><i className="fab fa-twitter"></i></a>
                </div>
                <div className="wrapper">
                
                    <a className="button" href="https://www.linkedin.com/in/valeriaresendez/" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1200">
                        <div className="icon">
                            <i className="fab fa-linkedin"></i>
                        </div>
                        <span>Linkedin</span>
                    </a>
                    
                    <a className="button" href="https://orcid.org/0000-0003-3623-5728" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1400">
                        <div className="icon">
                            <i className="fab fa-orcid"></i>
                        </div>
                        <span>Orcid</span>
                    </a>

                    <a className="button" href="https://twitter.com/vresendezg" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1400">
                        <div className="icon">
                            <i className="fab fa-twitter"></i>
                        </div>
                        <span>Twitter</span>
                    </a>
                    
                </div>

                <Link to="sobre-mi" href="#sobre-mi">
                    <div className="scroll-down"></div>
                </Link>
            </div>
        </section>
    </div>
);

export default Content;