import React from 'react';
import './Footer.css';

/* Multi idioma */
// import {FormattedMessage} from 'react-intl';


const Footer = () =>{
    // Fecha
    let fecha = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="site-footer">
                <div className="copyright">
                    <p>
                        {/* <FormattedMessage
                            id='footer-info'
                            defaultMessage='Page created by Nahuel61920'
                        /> */}
                    </p>
                    <p>&copy; {fecha}. All Rights Reserved.</p>
                </div>
                <div className="redes-sociales">
                    <a href="https://www.linkedin.com/in/esteban-nahuel-carrizo-69715422b/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                    <a href="https://orcid.org/0000-0003-3623-5728" target="_blank" rel="noopener noreferrer"><i className="fab fa-orcid"></i></a>
                    <a href="https://www.twitter.com/vresendezg/" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                </div>
            </div>
        </footer>
    )
};

export default React.memo(Footer);