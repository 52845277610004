import React from 'react';
import './AboutPage.css'

/* Componet */
import HeaderPage from '../../components/Header/HeaderPage';
import Footer from '../../components/Footer/Footer';
// import ParticleBackground from "../../components/ParticlesBg/ParticleBackground";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
//import cv from '../../cv/VRCV.pdf';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Img */

const About = () => {
  const proyectImg = require.context('../../img', true);

  function readMore() {
    let btnHide = document.querySelector("#btn-hide");
    let parrafoActive = document.querySelector(".parrafo-active");

    parrafoActive.classList.toggle("show");

    if (parrafoActive.classList.contains("show")) {
      btnHide.innerHTML = "↑";
    } else {
      btnHide.innerHTML = "Read more";
    }
  }

  return (
    <div>

      <HeaderPage />

      {/* <ParticleBackground /> */}

      <main>
        <section className="sobre-mi-seccion" id="sobre-mi">
          <div className="sobre-mi-container">
            <div className="sobre-mi-img-container">
              
              <div className="columns" data-aos="fade-right" data-aos-delay="300">
                <div className="audio">
                    <span className="wave"></span>
                    <span className="wave"></span>
                    <span className="wave"></span>
                    <span className="wave"></span>
                    <span className="wave"></span>
                </div>
            </div>
              {/* <a href={cv} target="_blank" rel="noopener noreferrer" download="VRCV.pdf" className="btn-codigo cv buttonDownload">
                    <FormattedMessage
                      id='btn-cv'
                      defaultMessage='Download CV'
                  /> 
              </a>*/}
            </div>
            <div className="sobre-mi-info">
              <p>
                <b>
                <FormattedMessage
                  id='about-info-1'
                  defaultMessage="Hi there!" 
                />
                </b>
              </p>
              <p>
                <FormattedMessage
                  id='about-info-1'
                  defaultMessage="My name is Valeria Resendez, I am a quantitative researcher. I persue my PhD at the Amsterdam School of Communication Research (ASCoR) of the University of Amsterdam (UvA). I'm also a proud member of the AI, Media, and Democracy Lab, where we're exploring the exciting world of artificial intelligence and its potential impact on media and society."
                />
              </p>
           <div className="projects__grid apps ">
                <div className="projects__item">
                    <a >
                        <img src={proyectImg(`./AIAct.jpg`)} alt="" className="projects__img" />
                    </a>
                    
                </div>
                <div className="projects__item">
                <a >
                        <img src={proyectImg(`./CAs.jpg`)} alt="" className="projects__img" />
                    </a>
                    
                </div>
          </div>
          <div>
            <div className="hide parrafo-active">
                  <p>
                    <FormattedMessage
                      id='about-info-2'
                      defaultMessage="

                      With my experience in the field of artificial intelligence and automation for enhancing customer experience in Mexico, I'm passionate about gathering insights for developing responsible technologies. I've had the pleasure of collaborating with Mexican institutions (CMinds, IA2030MX coalition) for this purpose.
                      
                      My current research focuses on emerging technologies for journalism, including conversational agents (think of voice assistants like Alexa or Siri). I'm particularly interested in how these technologies intersect with human-machine communication, political communication, and legislation. My project aims to provide empirical evidence for assessing legal frameworks on artificial intelligence in the news sector, which could have far-reaching implications for how we receive and consume news.
                      
                      Right now, I'm working on a news quality assessment of voice assistants, which involves developing sock puppets that can inquire in different languages and with different queries about news briefings to voice assistants. This evaluation would lead to a semi-automated content analysis to assess the diversity of topics and sources that voice assistants can provide for users. In multinational countries such as the Netherlands, this approach could help assess policymakers on the information accessibility established by distinct technologies.
                      
                      "
                    />
                  </p>

                  
            </div>
            <div className="btn-info">
                <div className="custom-btn btn-codigo" id="btn-hide" onClick={readMore}><span>Read more</span></div>
            </div>
          
          
          </div>
          <div className="projects__item">
                <a >
                        <img src={proyectImg(`./ppt.jpg`)} alt="" className="projects__img" />
                    </a>
          </div>
          <div>
          <p>
                  <FormattedMessage
                    id='about-info-2'
                    defaultMessage="
                    I'm excited about share more about my research and would love to connect with others who share my passion for responsible AI frameworks and emerging technologies for journalism. If you are interested in this topic get in touch and let's talk more about how to create a brighter future with emerging technologies!"
                  />
          </p>
          </div>
          </div>

          </div>
        </section>
      </main>
     
      <ScrollToTop />

      <Footer />

    </div>
  )
}
export default About;