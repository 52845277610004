import React from 'react';

/* Componet */
import About from './About';
import Service from './Service';
import Project from './Project';
import Contact from './Contact';

const Main = () => (
    <div>
        <About />        
        <Service />
        <Project />
        <Contact />
    </div>
);

export default Main;