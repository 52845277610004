import React from 'react';
import { Chrono } from "react-chrono";
/* Multi idioma */
import { FormattedMessage } from 'react-intl';
import './Service.css'


const Service = () => {
    const items = [{
        title: "August 2014 - December 2018",
        cardTitle: "B.A. Communication and Digital Media",
        cardSubtitle:"Instituto Tecnologico y de Estudios Superiores de Monterrey",
        
      }, 
      {
        title: "August 2019 - July 2020",
        cardTitle: "Msc. Communication Studies",
        cardSubtitle:"University of Twente",
        },
      {
        title: "March 2021 - March 2024",
        cardTitle: "PhD. Communication Studies",
        cardSubtitle:"University of Amsterdam",
        },
    
    ];

    return (
    <main>
    <section className="servicios" id="servicios">
        <h2 className="heading">
            <FormattedMessage
                id='Education'
                defaultMessage='Education'
            />
        </h2>
        
        <div className="row">
            <div className='timeline'
                style={{
                maxWidth: "800px",
                marginLeft: "auto",
                marginRight: "auto",
                color: "white"
                }}
            >
            <Chrono items={items} mode="HORIZONTAL" cardHeight="50" cardWidth="300" theme={{
                    primary: '#6096d3',
                    secondary: 'white',
                    cardBgColor: 'white',
                    cardForeColor: 'black',
                    titleColor: 'black',
                    titleColorActive: '#6096d3',
                }}>
            
            </Chrono>
                
                
            </div>
            
        </div>
        
    </section>
    </main>
    )

}
export default Service;

// export default React.memo(Service);