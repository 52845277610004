import React from 'react';
import '../../pages/About/AboutPage.css'
import { Link } from 'react-router-dom';
import cv from '../../cv/VRCV.pdf';
import { ButtomGet } from '../ButtomGet/ButtomGet';
import './About.css';
import imgabout from '../../img/ValeriaSmall.jpg';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

const About = () => (
    <main>
    <section className="sobre-mi" id="sobre-mi">
        <h2 className="heading">
            <FormattedMessage
                id='about'
                defaultMessage='About me'
            />
        </h2>

        <div className="row container">
            <div className="columns" data-aos="fade-right" data-aos-delay="300">
                <h3>
                    <FormattedMessage
                        id='im'
                        defaultMessage='who I am'
                    />
                </h3>
                <h4>
                    <FormattedMessage
                        id='description'
                        defaultMessage='Valeria Resendez'
                    />
                </h4>
                <p>
                    <FormattedMessage
                        id='my-description'
                        defaultMessage='I am a quantitative researcher. I did my PhD at the Amsterdam School of Communication Research (ASCoR) of the University of Amsterdam (UvA), and a research member of the AI, Media, and Democracy Lab. My research focuses on topics such as AI, conversational agents, and opinion formation. I completed my master (MsC. Communication specialized in Technology, cum laude) at the University of Twente. I previously worked in the field of artificial intelligence and automation for enhancing customer experience in Mexico. I also collaborated with Mexican institutions (CMinds, IA2030MX coalition) for developing responsible AI frameworks in the country. 
                        '
                    />
                </p>
                
                <div className="mas-info">
                    {/* <a href={cv} target="_blank" rel="noopener noreferrer" download="VRCV.pdf" className="btn-codigo buttonDownload">
                        <FormattedMessage
                            id='btn-cv'
                            defaultMessage='Download CV'
                        />
                    </a> */}
                    <div className='mas-info-btn'>
                    <Link to="/about">
                        <ButtomGet/>
                    </Link>
                    </div>
                </div>
            </div>
            <div className='sobre-mi-img-container'>
            <img src={imgabout} alt="" className="sobre-mi-img" />
            </div>
        </div>
        
    </section>
    
    </main>
);

export default React.memo(About);