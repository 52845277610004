import React from 'react';
// import '../../pages/Project/ProjectPage.css'
import { Link } from 'react-router-dom';
import { ButtomGet } from '../ButtomGet/ButtomGet';
import './Project.css';
/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Swiper */
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import "swiper/css/pagination";
// import { Pagination, Autoplay } from "swiper";

/* Img */
const proyectImg = require.context('../../img', true);

const Project = () => {
    return ( 
        <div className="project_background">
        <section className="proyectos" id="proyectos">
            <h2 className="heading">
                <FormattedMessage
                    id='projects'
                    defaultMessage='Projects'
                />
            </h2>
            <section className="projects__grid apps ">
                    
                    <div className="projects__item">
                        <a >
                        <Link to="/project">
                            <img src={proyectImg(`./writing.jpg`)} alt="" className="projects__img" />
                            <div className = "overlay_text">Publications and working papers</div>
                        </Link>
                        </a>
                    </div>
                    
                    
                    <div className="projects__item">
                        <a >
                        <Link to="/project">
                            <img src={proyectImg(`./events.jpg`)} alt="" className="projects__img" />
                            <div className = "overlay_text">Workshops and events</div>
                        </Link>
                        </a>
                    </div>
                    
                    
                </section>
            
            <div className='portafolio-btn'>
                <Link to="/project">
                    <ButtomGet/>
                </Link>
            </div>
        </section>
    </div>

    )
};
export default React.memo(Project);