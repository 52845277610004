import React from 'react';
import '../../pages/Contact/ContactPage.css'
/* Componet */
import HeaderPage from '../../components/Header/HeaderPage';
import Footer from '../../components/Footer/Footer';
/* TypedJs */
import Typical from 'react-typical';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';


/* Multi idioma */
import { FormattedMessage } from 'react-intl';

const Contact = () => {
    return (
    <div>
    <HeaderPage />
    <main>
        <section className="contactos" id="contactos">

            <h2 className="heading">
                <FormattedMessage
                    id='contact'
                    defaultMessage='Contact'
                />
            </h2>
            <h3 className="titulo" data-aos="fade-left" data-aos-delay="300">
                <FormattedMessage
                    id='contact-info'
                    defaultMessage='Contact me by: '
                />
                <Typical
                    className="site-contacto"
                    loop={Infinity}
                    wrapper="b"
                    steps={[
                        'Linkedin', 1500,
                        'Github', 1500,
                        'Twitter', 1500,
                    ]}
                />

            </h3>

            <div className="icons">
                
                
                <a href="https://twitter.com/vresendezg" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                    <div className="layer">
                        
                        <span className="fab fa-twitter"></span>
                    </div>
                    <div className="text">
                        Twitter
                    </div>
                </a>
                <a href="https://www.linkedin.com/in/valeriaresendez/" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                    <div className="layer">
                        
                        <span className="fab fa-linkedin-in"></span>
                    </div>
                    <div className="text">
                        Linkedin
                    </div>
                </a>
                <a href="https://orcid.org/0000-0003-3623-5728" target="_blank" rel="noopener noreferrer"  data-aos="zoom-in">

                    <div className="layer">
                        
                        <span className="fab fa-orcid"></span>
                    </div>
                    <div className="text">
                        Orcid
                    </div>
                </a>
            </div>
        </section>
    </main>
    <ScrollToTop />

    <Footer />
    
    </div>
    
)
            }

export default Contact;